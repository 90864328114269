<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaNotFound></ZaNotFound>

	2019-06-08	init

-->

<template>
	<div class="ZaNotFound" :class="elmClasses">
		<div class="ZaNotFound__inner">
			<ZaPageHeader
				:title="'Die Seite wurde leider nicht gefunden.<br/><br/>'"
			></ZaPageHeader>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import ZaPageHeader from '@/components/ZaPageHeader.vue'

	export default {
		name: 'ZaNotFound',
		components: {
			ZaPageHeader,
		},
		mixins: [],
		props: {},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaNotFound { // debug
		[showBorders2] & {}
	}
	.ZaNotFound { // vars
	}
	.ZaNotFound { // layout
	}
	.ZaNotFound { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
