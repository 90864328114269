import { render, staticRenderFns } from "./MhRouterView.vue?vue&type=template&id=f1f859a6&"
import script from "./MhRouterView.vue?vue&type=script&lang=js&"
export * from "./MhRouterView.vue?vue&type=script&lang=js&"
import style0 from "./MhRouterView.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../GbR/10__Projekte/2020-06-24__Zahnärzte Luci & Clausner/02  Website zahnaerzte-luci-clausner.de/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports